import './App.css';
import logo from './HalalControlLogo.jpg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img alt="Halal Control Logo" src={logo} width="250px" height="250px"/>
        <p>
          <strong>This site is under maintenance</strong>
        </p>
        <div className="contactInfo">
        <p>Contact us via</p>
        <p><h3><a href="mailto:info@halalcontrol.com.au">info@halalcontrol.com.au</a></h3></p>
        </div>
      </header>
    </div>
  );
}

export default App;
